<template>
  <v-row>
    <v-col
      cols="6"
      xs="6"
      class="text-left pb-0 mb-0"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="info darken-2"
        :to="{ name: 'savings.create_client' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-eye"
          class="pe-1"
        />
        Create a Client
      </v-btn>
    </v-col>
    <v-col
      cols="6"
      xs="6"
      class="text-right pb-0 mb-0"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'savings.clients_create_zone' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Create a Zone
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-row class="mt-2 mx-2 pt-0">
          <v-col
            cols="12"
            class="px-4 pt-2 mt-1 mb-0 pb-0"
          >
            <v-select
              v-model="selectedZoneFilter"
              ref="selectedZoneFilter"
              outlined
              dense
              item-value="id"
              item-text="info"
              :items="zone_types"
              label="Filter Clients By Zone"
            ></v-select>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-right"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getClients()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="mt-0 pt-0">
          <span class="me-3">({{ requestedClients.length }}) List Of Clients</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="requestedClients"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.username`]="{item}">
            <div class="d-flex flex-column" style="cursor: pointer">
              <router-link :to="'/savings/clients/profile/account-settings/'+item.id" class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}</router-link>
              <small>{{ item.no_campaigns }} (Saved: {{ item.amount_saved_so_far }})  & {{ item.no_loans }} (Paid: {{ item.amount_paid_so_far }})</small>
            </div>
          </template>
          <!-- status -->
          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="statusColor[item.status]"
              class="font-weight-medium"
            >
              {{ item.status }}
            </v-chip>
          </template>

          <template #[`item.phone_number`]="{item}">
            <a 
                :href="'https://api.whatsapp.com/send?phone='+item.phone_number+'/&text=Greetings *'+item.username+'* 😃, i hope you are doing great 💫 today. %0a I\'m a PettyCash core team member, and am glad to bring you the following on your account. You currently have:- %0a *'+item.no_campaigns+'*, %0a *'+item.no_loans+'*, %0a *'+item.amount+' Current Savings*, %0a *'+item.amount_owed_so_far+' Debts.*'" 
                target="_blank"
                style="text-decoration: none"
            >
              <v-btn class="success" elevation="6" icon small>
                <font-awesome-icon icon="fa-solid fa-comments"/>
              </v-btn>
            </a>
          </template>
          <template #[`item.date_time`]="{item}">
            {{ item.date_time }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Active: 'primary',
      Validated: 'success',
      Inactive: 'error',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'username' },
        { text: 'Principal Campaign', value: 'amount' },
        { text: 'Loan Target', value: 'amount_owed_so_far' },
        { text: 'Savings Target', value: 'amount_savings_target_so_far' },
        { text: 'Status', value: 'status' },
        { text: 'Chat', value: 'phone_number' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      selectedZoneFilter: "",
      zone_types: [],
      requestedClients: [],
      statusColor,
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,
      loading: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      // this.getClients();
      document.title = "Manager: Savings | List of Clients"
      this.getZones()
  },

  methods:{
    async getZones(){
      await axios
        .get('/api/v1/manager/get/topup/zones/')
        .then(response => {
          this.zone_types = response.data  // get the data and fill into campaigns
          console.log(this.zone_types)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
    
    async getClients(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }

      if (this.selectedZoneFilter === ''){
        this.$store.commit('setSnackBarMessage', "Please select a zone")
        this.$store.commit('setSnackBarColor', "red darken-4")
        this.$store.commit('activateSnackBar', true)
        this.$refs.selectedZoneFilter.focus()  // this causes a an auto fucos to the element
      }
      else{
        this.loading = true
        await axios
          .get('/api/v1/manager/get/clients/'+this.selectedZoneFilter+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
          .then(response => {
            this.requestedClients = response.data  // get the data and fill into campaigns
            console.log(this.requestedClients)
          })
          .catch(error => {
            if (error.response){
              this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }else{
              this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
            }
          })
        this.loading = false
      }
    },
  },
}
</script>
